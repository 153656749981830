var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "market block_bg" }, [
    _c("div", { ref: "marketTop", staticClass: "market_top" }, [
      _c("div", { staticClass: "search_box" }, [
        _c("i", { staticClass: "iconfont icon-search" }),
        _c("form", { attrs: { action: "" } }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.searchVal,
                expression: "searchVal",
                modifiers: { trim: true }
              }
            ],
            attrs: { type: "text", placeholder: _vm.$t("tradeLang.lang30") },
            domProps: { value: _vm.searchVal },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.searchVal = $event.target.value.trim()
              },
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          })
        ])
      ]),
      _c("ol", { staticClass: "market_nav flex" }, [
        _c(
          "li",
          {
            class: [{ nav_active: _vm.active == 3 }],
            on: {
              click: function($event) {
                _vm.active = 3
                _vm.scrollToCenter($event)
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("addLang.lang37")))]
        ),
        _c(
          "li",
          {
            class: [{ nav_active: _vm.active == 1 }],
            on: {
              click: function($event) {
                _vm.active = 1
                _vm.scrollToCenter($event)
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("addLang.lang38")))]
        ),
        _c(
          "li",
          {
            class: [{ nav_active: _vm.active == 4 }],
            on: {
              click: function($event) {
                _vm.active = 4
                _vm.scrollToCenter($event)
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("homeLang.lang77")))]
        ),
        _c(
          "li",
          {
            class: [{ nav_active: _vm.active == 5 }],
            on: {
              click: function($event) {
                _vm.active = 5
                _vm.scrollToCenter($event)
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("homeLang.lang78")))]
        )
      ]),
      _c("div", { staticClass: "main_top_box" }, [
        _c("ol", { staticClass: "sort_box flex" }, [
          _c("li", [
            _c(
              "span",
              {
                on: {
                  click: function($event) {
                    return _vm.changeSort(1)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("tradeLang.lang3")))]
            ),
            _c("img", {
              staticClass: "sort-img",
              attrs: {
                src: require("@img/" +
                  _vm.$store.state.common.theme +
                  "/" +
                  (_vm.volSort == "up"
                    ? "sort_up"
                    : _vm.volSort == "down"
                    ? "sort_down"
                    : "sort_no") +
                  ".png"),
                alt: ""
              }
            })
          ]),
          _c("li", [
            _c(
              "span",
              {
                on: {
                  click: function($event) {
                    return _vm.changeSort(2)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("tradeLang.lang4")))]
            ),
            _c("img", {
              staticClass: "sort-img",
              attrs: {
                src: require("@img/" +
                  _vm.$store.state.common.theme +
                  "/" +
                  (_vm.priceSort == "up"
                    ? "sort_up"
                    : _vm.priceSort == "down"
                    ? "sort_down"
                    : "sort_no") +
                  ".png"),
                alt: ""
              }
            })
          ]),
          _c("li", [
            _c(
              "span",
              {
                on: {
                  click: function($event) {
                    return _vm.changeSort(3)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("tradeLang.lang5a")))]
            ),
            _c("img", {
              staticClass: "sort-img",
              attrs: {
                src: require("@img/" +
                  _vm.$store.state.common.theme +
                  "/" +
                  (_vm.riseSort == "up"
                    ? "sort_up"
                    : _vm.riseSort == "down"
                    ? "sort_down"
                    : "sort_no") +
                  ".png"),
                alt: ""
              }
            })
          ])
        ])
      ])
    ]),
    _c("div", { ref: "marketBox", staticClass: "market_content" }, [
      _vm.active == 1
        ? _c(
            "div",
            [
              _vm._l(_vm.exchangeList, function(item) {
                return _c("div", { key: item.autoid }, [
                  (item.isOptional && _vm.mainNav == 1) ||
                  _vm.mainNav == 2 ||
                  (_vm.mainNav == 3 && item.plate == "main") ||
                  (_vm.mainNav == 4 && item.plate == "pioneer")
                    ? _c(
                        "ol",
                        {
                          staticClass: "coin_box flex",
                          on: {
                            click: function($event) {
                              return _vm.goDetails(item)
                            }
                          }
                        },
                        [
                          _c("li", [
                            _c("div", { staticClass: "flex" }, [
                              _c("img", {
                                attrs: { src: _vm.getImgUrl(item.logo) }
                              }),
                              _vm._v(" "),
                              _c("h3", [_vm._v(_vm._s(item.tradcoin))]),
                              _c("span", [_vm._v("/" + _vm._s(item.maincoin))])
                            ]),
                            _c("p", [_vm._v("VOL " + _vm._s(item.volume))])
                          ]),
                          _c("li", [
                            _c(
                              "p",
                              {
                                class: [
                                  item.rise < 0 ? "red_color" : "green_color"
                                ]
                              },
                              [_vm._v(_vm._s(item.price))]
                            ),
                            _c("span", [
                              _vm._v(
                                "≈ " +
                                  _vm._s(_vm.rateData.symbol) +
                                  _vm._s(
                                    _vm._f("mathFloor")(
                                      item.price * _vm.rateData.price,
                                      item.price_accuracy
                                    )
                                  )
                              )
                            ])
                          ]),
                          _c("li", [
                            _c(
                              "span",
                              {
                                class: [
                                  item.rise < 0 ? "rise_red" : "rise_green"
                                ]
                              },
                              [
                                item.rise >= 0
                                  ? _c("em", [_vm._v("+")])
                                  : _vm._e(),
                                _vm._v(_vm._s(item.rise) + "%")
                              ]
                            )
                          ])
                        ]
                      )
                    : _vm._e()
                ])
              }),
              (_vm.mainNav == 1 && !_vm.hasExchangeOptional) ||
              (_vm.mainNav == 4 && !_vm.hasExchangePioneer)
                ? _c("div", { staticClass: "no_data" }, [
                    _c("p", { staticClass: "icon_no_data" }),
                    _c("span", [_vm._v(_vm._s(_vm.$t("commonLang.lang4")))])
                  ])
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm.active == 2
        ? _c(
            "div",
            [
              _vm._l(_vm.futuresList, function(item) {
                return _c("div", { key: item.autoid }, [
                  (item.isOptional && _vm.mainNav == 1) ||
                  _vm.mainNav == 2 ||
                  (_vm.mainNav == 3 && item.plate == "main") ||
                  (_vm.mainNav == 4 && item.plate == "pioneer")
                    ? _c(
                        "ol",
                        {
                          staticClass: "coin_box flex",
                          on: {
                            click: function($event) {
                              return _vm.goDetails(item)
                            }
                          }
                        },
                        [
                          _c("li", [
                            _c("div", { staticClass: "flex" }, [
                              _c("img", {
                                attrs: { src: _vm.getImgUrl(item.logo) }
                              }),
                              _vm._v(" "),
                              _c("h3", [_vm._v(_vm._s(item.tradcoin))]),
                              _c("span", [_vm._v("/" + _vm._s(item.maincoin))])
                            ]),
                            _c("p", [_vm._v("VOL " + _vm._s(item.volume))])
                          ]),
                          _c("li", [
                            _c(
                              "p",
                              {
                                class: [
                                  item.rise < 0 ? "red_color" : "green_color"
                                ]
                              },
                              [_vm._v(_vm._s(item.price))]
                            ),
                            _c("span", [
                              _vm._v(
                                "≈ " +
                                  _vm._s(_vm.rateData.symbol) +
                                  _vm._s(
                                    _vm._f("mathFloor")(
                                      item.price * _vm.rateData.price,
                                      item.price_accuracy
                                    )
                                  )
                              )
                            ])
                          ]),
                          _c("li", [
                            _c(
                              "span",
                              {
                                class: [
                                  item.rise < 0 ? "rise_red" : "rise_green"
                                ]
                              },
                              [
                                item.rise >= 0
                                  ? _c("em", [_vm._v("+")])
                                  : _vm._e(),
                                _vm._v(_vm._s(item.rise) + "%")
                              ]
                            )
                          ])
                        ]
                      )
                    : _vm._e()
                ])
              }),
              (_vm.mainNav == 1 && !_vm.hasFuturesOptional) ||
              (_vm.mainNav == 4 && !_vm.hasFuturesPioneer)
                ? _c("div", { staticClass: "no_data" }, [
                    _c("p", { staticClass: "icon_no_data" }),
                    _c("span", [_vm._v(_vm._s(_vm.$t("commonLang.lang4")))])
                  ])
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm.active == 3
        ? _c(
            "div",
            [
              _vm._l(_vm.cycleList, function(item) {
                return _c("div", { key: item.autoid }, [
                  (item.isOptional && _vm.mainNav == 1) ||
                  _vm.mainNav == 2 ||
                  (_vm.mainNav == 3 && item.plate == "main") ||
                  (_vm.mainNav == 4 && item.plate == "pioneer")
                    ? _c(
                        "ol",
                        {
                          staticClass: "coin_box flex",
                          on: {
                            click: function($event) {
                              return _vm.goDetails(item)
                            }
                          }
                        },
                        [
                          _c("li", [
                            _c("div", { staticClass: "flex" }, [
                              _c("img", {
                                attrs: { src: _vm.getImgUrl(item.logo) }
                              }),
                              _vm._v(" "),
                              _c("h3", [_vm._v(_vm._s(item.tradcoin))]),
                              _c("span", [_vm._v("/" + _vm._s(item.maincoin))])
                            ]),
                            _c("p", [_vm._v("VOL " + _vm._s(item.volume))])
                          ]),
                          _c("li", [
                            _c(
                              "p",
                              {
                                class: [
                                  item.rise < 0 ? "red_color" : "green_color"
                                ]
                              },
                              [_vm._v(_vm._s(item.price))]
                            ),
                            _c("span", [
                              _vm._v(
                                "≈ " +
                                  _vm._s(_vm.rateData.symbol) +
                                  _vm._s(
                                    _vm._f("mathFloor")(
                                      item.price * _vm.rateData.price,
                                      item.price_accuracy
                                    )
                                  )
                              )
                            ])
                          ]),
                          _c("li", [
                            _c(
                              "span",
                              {
                                class: [
                                  item.rise < 0 ? "rise_red" : "rise_green"
                                ]
                              },
                              [
                                item.rise >= 0
                                  ? _c("em", [_vm._v("+")])
                                  : _vm._e(),
                                _vm._v(_vm._s(item.rise) + "%")
                              ]
                            )
                          ])
                        ]
                      )
                    : _vm._e()
                ])
              }),
              (_vm.mainNav == 1 && !_vm.hasCycleOptional) ||
              (_vm.mainNav == 4 && !_vm.hasCyclePioneer)
                ? _c("div", { staticClass: "no_data" }, [
                    _c("p", { staticClass: "icon_no_data" }),
                    _c("span", [_vm._v(_vm._s(_vm.$t("commonLang.lang4")))])
                  ])
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm.active == 4
        ? _c(
            "div",
            [
              _vm._l(_vm.cycleForexList, function(item) {
                return _c("div", { key: item.autoid }, [
                  (item.isOptional && _vm.mainNav == 1) ||
                  _vm.mainNav == 2 ||
                  (_vm.mainNav == 3 && item.plate == "main") ||
                  (_vm.mainNav == 4 && item.plate == "pioneer")
                    ? _c(
                        "ol",
                        {
                          staticClass: "coin_box flex",
                          on: {
                            click: function($event) {
                              return _vm.goDetails(item)
                            }
                          }
                        },
                        [
                          _c("li", [
                            _c("div", { staticClass: "flex" }, [
                              _c("img", {
                                attrs: { src: _vm.getImgUrl(item.logo) }
                              }),
                              _vm._v(" "),
                              _c("h3", [_vm._v(_vm._s(item.tradcoin))]),
                              _c("span", [_vm._v("/" + _vm._s(item.maincoin))])
                            ]),
                            _c("p", [_vm._v("VOL " + _vm._s(item.volume))])
                          ]),
                          _c("li", [
                            _c(
                              "p",
                              {
                                class: [
                                  item.rise < 0 ? "red_color" : "green_color"
                                ]
                              },
                              [_vm._v(_vm._s(item.price))]
                            ),
                            _c("span", [
                              _vm._v(
                                "≈ " +
                                  _vm._s(_vm.rateData.symbol) +
                                  _vm._s(
                                    _vm._f("mathFloor")(
                                      item.price * _vm.rateData.price,
                                      item.price_accuracy
                                    )
                                  )
                              )
                            ])
                          ]),
                          _c("li", [
                            _c(
                              "span",
                              {
                                class: [
                                  item.rise < 0 ? "rise_red" : "rise_green"
                                ]
                              },
                              [
                                item.rise >= 0
                                  ? _c("em", [_vm._v("+")])
                                  : _vm._e(),
                                _vm._v(_vm._s(item.rise) + "%")
                              ]
                            )
                          ])
                        ]
                      )
                    : _vm._e()
                ])
              }),
              (_vm.mainNav == 1 && !_vm.hasCycleOptional) ||
              (_vm.mainNav == 4 && !_vm.hasCyclePioneer)
                ? _c("div", { staticClass: "no_data" }, [
                    _c("p", { staticClass: "icon_no_data" }),
                    _c("span", [_vm._v(_vm._s(_vm.$t("commonLang.lang4")))])
                  ])
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm.active == 5
        ? _c(
            "div",
            [
              _vm._l(_vm.cycleMetalList, function(item) {
                return _c("div", { key: item.autoid }, [
                  (item.isOptional && _vm.mainNav == 1) ||
                  _vm.mainNav == 2 ||
                  (_vm.mainNav == 3 && item.plate == "main") ||
                  (_vm.mainNav == 4 && item.plate == "pioneer")
                    ? _c(
                        "ol",
                        {
                          staticClass: "coin_box flex",
                          on: {
                            click: function($event) {
                              return _vm.goDetails(item)
                            }
                          }
                        },
                        [
                          _c("li", [
                            _c("div", { staticClass: "flex" }, [
                              _c("img", {
                                attrs: { src: _vm.getImgUrl(item.logo) }
                              }),
                              _vm._v(" "),
                              _c("h3", [_vm._v(_vm._s(item.tradcoin))]),
                              _c("span", [_vm._v("/" + _vm._s(item.maincoin))])
                            ]),
                            _c("p", [_vm._v("VOL " + _vm._s(item.volume))])
                          ]),
                          _c("li", [
                            _c(
                              "p",
                              {
                                class: [
                                  item.rise < 0 ? "red_color" : "green_color"
                                ]
                              },
                              [_vm._v(_vm._s(item.price))]
                            ),
                            _c("span", [
                              _vm._v(
                                "≈ " +
                                  _vm._s(_vm.rateData.symbol) +
                                  _vm._s(
                                    _vm._f("mathFloor")(
                                      item.price * _vm.rateData.price,
                                      item.price_accuracy
                                    )
                                  )
                              )
                            ])
                          ]),
                          _c("li", [
                            _c(
                              "span",
                              {
                                class: [
                                  item.rise < 0 ? "rise_red" : "rise_green"
                                ]
                              },
                              [
                                item.rise >= 0
                                  ? _c("em", [_vm._v("+")])
                                  : _vm._e(),
                                _vm._v(_vm._s(item.rise) + "%")
                              ]
                            )
                          ])
                        ]
                      )
                    : _vm._e()
                ])
              }),
              (_vm.mainNav == 1 && !_vm.hasCycleOptional) ||
              (_vm.mainNav == 4 && !_vm.hasCyclePioneer)
                ? _c("div", { staticClass: "no_data" }, [
                    _c("p", { staticClass: "icon_no_data" }),
                    _c("span", [_vm._v(_vm._s(_vm.$t("commonLang.lang4")))])
                  ])
                : _vm._e()
            ],
            2
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }