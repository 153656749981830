var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cycle_futures" },
    [
      _c(
        "div",
        { ref: "cycleBox", staticClass: "cycle_box" },
        [
          _c("div", { ref: "cycleTop", staticClass: "cycle_top" }, [
            _c("div", { staticClass: "coin_header flex_between" }, [
              _c(
                "label",
                {
                  staticClass: "flex",
                  on: {
                    click: function($event) {
                      return _vm.showLeftPopup()
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@img/common/icon_menu.png"),
                      alt: ""
                    }
                  }),
                  _c("h3", [
                    _vm._v(
                      _vm._s(_vm.coinInfo.tradcoin) +
                        "/" +
                        _vm._s(_vm.coinInfo.maincoin)
                    )
                  ])
                ]
              ),
              _c("p", [
                _c(
                  "span",
                  {
                    on: {
                      click: function($event) {
                        return _vm.pushPath("/cycleHistory")
                      }
                    }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@img/common/icon_info.png"),
                        alt: ""
                      }
                    })
                  ]
                )
              ])
            ]),
            _c("div", { staticClass: "coin_price flex_between" }, [
              _c("div", { staticClass: "price_left_box" }, [
                _c(
                  "h1",
                  {
                    class: [
                      _vm.coinInfo.rise >= 0 ? "green_color" : "red_color",
                      "text_hide"
                    ]
                  },
                  [_vm._v(_vm._s(_vm.coinInfo.price))]
                ),
                _c("label", { staticClass: "flex" }, [
                  _c("p", [
                    _vm._v(
                      "≈ " +
                        _vm._s(_vm.rateData.symbol) +
                        _vm._s(
                          _vm._f("mathFloor")(
                            _vm.coinInfo.price * _vm.rateData.price,
                            2
                          )
                        )
                    )
                  ]),
                  _vm.coinInfo.rise >= 0
                    ? _c("i", { staticClass: "green_color" }, [
                        _vm._v("+" + _vm._s(_vm.coinInfo.rise) + "%")
                      ])
                    : _vm._e(),
                  _vm.coinInfo.rise < 0
                    ? _c("i", { staticClass: "red_color" }, [
                        _vm._v(_vm._s(_vm.coinInfo.rise) + "%")
                      ])
                    : _vm._e()
                ])
              ]),
              _c("div", { staticClass: "price_right_box" }, [
                _c("p", [
                  _c("span", [_vm._v(_vm._s(_vm.coinInfo.height))]),
                  _vm._v(" "),
                  _c("em", [_vm._v(_vm._s(_vm.$t("tradeLang.lang15")))])
                ]),
                _c("p", [
                  _c("span", [_vm._v(_vm._s(_vm.coinInfo.low))]),
                  _vm._v(" "),
                  _c("em", [_vm._v(_vm._s(_vm.$t("tradeLang.lang16")))])
                ]),
                _c("p", [
                  _c("span", [_vm._v(_vm._s(_vm.coinInfo.volume))]),
                  _vm._v(" "),
                  _c("em", [_vm._v(_vm._s(_vm.$t("tradeLang.lang17")))])
                ])
              ])
            ])
          ]),
          _c(
            "div",
            { staticClass: "kline_box" },
            [
              _vm.coinInfo.tradcoin
                ? _c("kline", {
                    key: _vm.klineKey,
                    ref: "klinechart",
                    attrs: {
                      "coin-type": "cycle",
                      "default-pair-name":
                        _vm.coinInfo.tradcoin + "/" + _vm.coinInfo.maincoin,
                      "default-name": _vm.coinInfo.tradcoin,
                      "defaultfloat-precision": _vm.coinInfo.price_accuracy,
                      "default-period": "4"
                    },
                    on: { periodData: _vm.changePerioad }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "van-tabs",
            {
              staticClass: "record-container",
              attrs: { border: "", "line-height": 1, "line-width": 20 },
              model: {
                value: _vm.active,
                callback: function($$v) {
                  _vm.active = $$v
                },
                expression: "active"
              }
            },
            [
              _c(
                "van-tab",
                { attrs: { title: _vm.$t("tradeLang.lang281") } },
                [
                  _vm._l(_vm.positionOrder, function(item) {
                    return _c(
                      "ol",
                      { key: item.orderid, staticClass: "position_box" },
                      [
                        _c("li", { staticClass: "position_top flex_between" }, [
                          _c("h2", [
                            _vm._v(
                              _vm._s(item.tradcoin) +
                                "/" +
                                _vm._s(item.maincoin)
                            )
                          ]),
                          _c("i", [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatDate")(
                                  Number(item.wdate),
                                  "MM-dd hh:mm:ss"
                                )
                              )
                            )
                          ]),
                          item.type == "buy"
                            ? _c("em", { staticClass: "green_color" }, [
                                _vm._v(_vm._s(_vm.$t("tradeLang.lang235")))
                              ])
                            : _vm._e(),
                          item.type == "sell"
                            ? _c("em", { staticClass: "red_color" }, [
                                _vm._v(_vm._s(_vm.$t("tradeLang.lang236")))
                              ])
                            : _vm._e()
                        ]),
                        _c(
                          "li",
                          { staticClass: "position_center flex_between" },
                          [
                            _c("div", [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("tradeLang.lang238")) +
                                    "(" +
                                    _vm._s(item.maincoin) +
                                    ")"
                                )
                              ]),
                              _c("p", [_vm._v(_vm._s(item.price || "--"))]),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("tradeLang.lang239")))
                              ]),
                              _c(
                                "p",
                                {
                                  class: [
                                    _vm.coinInfo.rise >= 0
                                      ? "green_color"
                                      : "red_color"
                                  ]
                                },
                                [_vm._v(_vm._s(_vm.coinInfo.price))]
                              )
                            ]),
                            _c("div", [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("tradeLang.lang240")) +
                                    "(" +
                                    _vm._s(item.paycoin) +
                                    ")"
                                )
                              ]),
                              _c("p", [_vm._v(_vm._s(item.number || "--"))]),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("tradeLang.lang241")))
                              ]),
                              item.type == "buy" &&
                              Number(_vm.coinInfo.price) > Number(item.price) &&
                              _vm.gasType == "profit"
                                ? _c(
                                    "p",
                                    { staticClass: "green_color text_hide" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("mathFloor1")(
                                            item.number * item.profitGas,
                                            4
                                          )
                                        )
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              item.type == "buy" &&
                              Number(_vm.coinInfo.price) <=
                                Number(item.price) &&
                              _vm.gasType == "profit"
                                ? _c(
                                    "p",
                                    { staticClass: "red_color text_hide" },
                                    [
                                      _vm._v(
                                        "-" +
                                          _vm._s(
                                            _vm._f("mathFloor1")(
                                              item.number * item.lossGas,
                                              4
                                            )
                                          )
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              item.type == "sell" &&
                              Number(_vm.coinInfo.price) < Number(item.price) &&
                              _vm.gasType == "profit"
                                ? _c(
                                    "p",
                                    { staticClass: "green_color text_hide" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("mathFloor1")(
                                            item.number * item.profitGas,
                                            4
                                          )
                                        )
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              item.type == "sell" &&
                              Number(_vm.coinInfo.price) >=
                                Number(item.price) &&
                              _vm.gasType == "profit"
                                ? _c(
                                    "p",
                                    { staticClass: "red_color text_hide" },
                                    [
                                      _vm._v(
                                        "-" +
                                          _vm._s(
                                            _vm._f("mathFloor1")(
                                              item.number * item.lossGas,
                                              4
                                            )
                                          )
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]),
                            _c("div", [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("tradeLang.lang242")))
                              ]),
                              _c("p", [_vm._v(_vm._s(item.lever) + "s")]),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("tradeLang.lang243")))
                              ]),
                              _c("p", [_vm._v(_vm._s(item.gas || "--"))])
                            ])
                          ]
                        )
                      ]
                    )
                  }),
                  !_vm.positionOrder.length
                    ? _c("div", { staticClass: "no_data" }, [
                        _c("p", { staticClass: "icon_no_data" }),
                        _c("span", [_vm._v(_vm._s(_vm.$t("commonLang.lang5")))])
                      ])
                    : _vm._e()
                ],
                2
              ),
              _c(
                "van-tab",
                { attrs: { title: _vm.$t("tradeLang.lang282") } },
                [
                  _c(
                    "van-list",
                    {
                      attrs: { finished: _vm.finished },
                      on: { load: _vm.loadMore },
                      model: {
                        value: _vm.loading,
                        callback: function($$v) {
                          _vm.loading = $$v
                        },
                        expression: "loading"
                      }
                    },
                    _vm._l(_vm.historyOrder, function(item) {
                      return _c(
                        "ol",
                        {
                          key: item.autoid,
                          staticClass: "position_box",
                          on: {
                            click: function($event) {
                              return _vm.showDetailsPopup(item)
                            }
                          }
                        },
                        [
                          _c(
                            "li",
                            { staticClass: "position_top flex_between" },
                            [
                              _c("h2", [
                                _vm._v(
                                  _vm._s(item.tradcoin) +
                                    "/" +
                                    _vm._s(item.maincoin)
                                )
                              ]),
                              item.wdate
                                ? _c("i", [_vm._v(_vm._s(item.wdate))])
                                : _vm._e(),
                              item.type == "buy"
                                ? _c("em", { staticClass: "green_color" }, [
                                    _vm._v(_vm._s(_vm.$t("tradeLang.lang235")))
                                  ])
                                : _vm._e(),
                              item.type == "sell"
                                ? _c("em", { staticClass: "red_color" }, [
                                    _vm._v(_vm._s(_vm.$t("tradeLang.lang236")))
                                  ])
                                : _vm._e()
                            ]
                          ),
                          _c(
                            "li",
                            { staticClass: "position_center flex_between" },
                            [
                              _c("div", [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("tradeLang.lang238")) +
                                      "(" +
                                      _vm._s(item.maincoin) +
                                      ")"
                                  )
                                ]),
                                _c("p", [_vm._v(_vm._s(item.price || "--"))]),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("tradeLang.lang245")))
                                ]),
                                _c("p", [
                                  _vm._v(_vm._s(item.last_profit || "--"))
                                ])
                              ]),
                              _c("div", [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("tradeLang.lang240")) +
                                      "(" +
                                      _vm._s(item.paycoin) +
                                      ")"
                                  )
                                ]),
                                _c("p", [_vm._v(_vm._s(item.number || "--"))]),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("tradeLang.lang241")))
                                ]),
                                _c(
                                  "p",
                                  {
                                    class: [
                                      item.income >= 0
                                        ? "green_color"
                                        : "red_color"
                                    ]
                                  },
                                  [_vm._v(_vm._s(item.income || "--"))]
                                )
                              ]),
                              _c("div", [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("tradeLang.lang242")))
                                ]),
                                _c("p", [_vm._v(_vm._s(item.lever) + "s")]),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("tradeLang.lang243")))
                                ]),
                                _c("p", [_vm._v(_vm._s(item.gas || "--"))])
                              ])
                            ]
                          ),
                          _c("li", { staticClass: "position_bottom" }, [
                            _c("div", { staticClass: "flex" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("tradeLang.lang246")))
                              ]),
                              _c("p", [_vm._v(_vm._s(item.closedate))])
                            ])
                          ])
                        ]
                      )
                    }),
                    0
                  ),
                  !_vm.historyOrder.length && _vm.isload
                    ? _c("div", { staticClass: "no_data" }, [
                        _c("p", { staticClass: "icon_no_data" }),
                        _c("span", [_vm._v(_vm._s(_vm.$t("commonLang.lang5")))])
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "btn_box" },
            [
              _c(
                "van-button",
                {
                  staticClass: "green_bg",
                  on: {
                    click: function($event) {
                      return _vm.showTradePopup(1)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("tradeLang.lang283")))]
              ),
              _c(
                "van-button",
                {
                  staticClass: "red_bg",
                  on: {
                    click: function($event) {
                      return _vm.showTradePopup(2)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("tradeLang.lang284")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "van-popup",
        {
          ref: "leftPopup",
          staticClass: "left_popup",
          attrs: {
            "safe-area-inset-bottom": "",
            "get-container": "#app",
            "lazy-render": false
          },
          model: {
            value: _vm.leftPopup,
            callback: function($$v) {
              _vm.leftPopup = $$v
            },
            expression: "leftPopup"
          }
        },
        [
          _c("div", { staticClass: "left_title" }, [
            _vm._v(_vm._s(_vm.$t("tradeLang.lang252")))
          ]),
          _c(
            "ol",
            _vm._l(_vm.coinList, function(item) {
              return _c(
                "li",
                {
                  key: item.autoid,
                  class: [
                    { coin_selected: _vm.coinInfo.tradcoin == item.tradcoin },
                    "flex_between van-hairline--bottom"
                  ],
                  on: {
                    click: function($event) {
                      return _vm.chooseCoin(item)
                    }
                  }
                },
                [
                  _c("div", [
                    _c("span", [
                      _c("img", { attrs: { src: _vm.getImgUrl(item.logo) } }),
                      _vm._v(
                        _vm._s(item.tradcoin) + "/" + _vm._s(item.maincoin)
                      )
                    ]),
                    _vm.$i18n.locale == "CN"
                      ? _c("label", [_vm._v(_vm._s(item.chinaname))])
                      : _c("label", [_vm._v(_vm._s(item.symbol))])
                  ]),
                  _c(
                    "p",
                    { class: [item.rise >= 0 ? "red_color" : "green_color"] },
                    [_vm._v(_vm._s(item.price))]
                  )
                ]
              )
            }),
            0
          )
        ]
      ),
      _c(
        "van-popup",
        {
          staticClass: "trade_popup",
          attrs: {
            position: "bottom",
            "safe-area-inset-bottom": "",
            "get-container": "#app"
          },
          model: {
            value: _vm.tradePopup,
            callback: function($$v) {
              _vm.tradePopup = $$v
            },
            expression: "tradePopup"
          }
        },
        [
          _c("div", { staticClass: "trade_popup_title flex_between" }, [
            _c("div", { staticClass: "flex" }, [
              _c("h3", [
                _vm._v(
                  _vm._s(_vm.coinInfo.tradcoin) +
                    "/" +
                    _vm._s(_vm.coinInfo.maincoin)
                )
              ]),
              _vm.coinInfo.rise >= 0
                ? _c("p", { staticClass: "green_color" }, [
                    _vm._v(_vm._s(_vm.coinInfo.price))
                  ])
                : _vm._e(),
              _vm.coinInfo.rise < 0
                ? _c("p", { staticClass: "red_color" }, [
                    _vm._v(_vm._s(_vm.coinInfo.price))
                  ])
                : _vm._e()
            ]),
            _c(
              "a",
              {
                on: {
                  click: function($event) {
                    _vm.tradePopup = false
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("commonLang.lang1")))]
            )
          ]),
          _c("ol", { staticClass: "trade_popup_ol" }, [
            _c("li", [
              _c("label", [_vm._v(_vm._s(_vm.$t("tradeLang.lang253")))]),
              _c(
                "div",
                _vm._l(_vm.payCoinList, function(item) {
                  return _c(
                    "p",
                    {
                      key: item.paycoin,
                      class: [
                        { tab_select: _vm.payCoinData.paycoin == item.paycoin }
                      ],
                      on: {
                        click: function($event) {
                          return _vm.selectPayCoin(item)
                        }
                      }
                    },
                    [_vm._v(_vm._s(item.paycoin))]
                  )
                }),
                0
              )
            ]),
            _c("li", [
              _c("label", [_vm._v(_vm._s(_vm.$t("tradeLang.lang254")))]),
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.usable,
                      expression: "usable"
                    }
                  ],
                  attrs: { type: "text", disabled: "", readonly: "" },
                  domProps: { value: _vm.usable },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.usable = $event.target.value
                    }
                  }
                })
              ])
            ]),
            _c("li", [
              _c("label", [_vm._v(_vm._s(_vm.$t("tradeLang.lang255")))]),
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.tradeNum,
                      expression: "tradeNum"
                    }
                  ],
                  attrs: {
                    type: "number",
                    placeholder: _vm.$t("tradeLang.lang256", {
                      min: _vm.leverData.minnumber,
                      coin: _vm.payCoinData.paycoin
                    })
                  },
                  domProps: { value: _vm.tradeNum },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.tradeNum = $event.target.value
                    }
                  }
                })
              ])
            ]),
            _c("li", [
              _c("label", [_vm._v(_vm._s(_vm.$t("tradeLang.lang242")))]),
              _c(
                "div",
                _vm._l(_vm.leverList, function(item) {
                  return _c(
                    "p",
                    {
                      key: item.autoid,
                      class: [
                        { tab_select: _vm.leverData.lever == item.lever }
                      ],
                      on: {
                        click: function($event) {
                          return _vm.chooseCycle(item)
                        }
                      }
                    },
                    [_vm._v(_vm._s(item.lever) + "s")]
                  )
                }),
                0
              )
            ])
          ]),
          _c("div", { staticClass: "profit_box flex_between" }, [
            _c("p", [
              _vm._v(_vm._s(_vm.$t("tradeLang.lang257"))),
              _c("em", [
                _vm._v(
                  _vm._s(
                    _vm._f("mathFloor")(_vm.leverData.profitGas * 100, 2)
                  ) + "%"
                )
              ])
            ]),
            _c("p", [
              _vm._v(_vm._s(_vm.$t("tradeLang.lang258"))),
              _c("em", [
                _vm._v(
                  _vm._s(
                    _vm._f("mathFloor1")(
                      _vm.tradeNum * _vm.leverData.profitGas,
                      8
                    )
                  )
                )
              ])
            ])
          ]),
          _c(
            "div",
            { staticClass: "trade_popup_btn" },
            [
              _vm.tradeType == 1
                ? _c(
                    "van-button",
                    {
                      staticClass: "green_bg",
                      on: {
                        click: function($event) {
                          return _vm.addOrder("buy")
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("tradeLang.lang235")))]
                  )
                : _c(
                    "van-button",
                    {
                      staticClass: "red_bg",
                      on: {
                        click: function($event) {
                          return _vm.addOrder("sell")
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("tradeLang.lang236")))]
                  )
            ],
            1
          )
        ]
      ),
      _vm._l(_vm.positionOrder, function(item, index) {
        return _c("drag-box", {
          key: "cycle" + item.autoid,
          attrs: {
            id: "cycle" + item.autoid,
            "offset-time": _vm.offsetTime,
            "now-price": _vm.coinInfo.price,
            data: item,
            top: 100 + index * 150
          },
          on: {
            dragNotice: _vm.updatePosition,
            refreshData: _vm.refreshHistory
          }
        })
      }),
      _vm.showConfirm
        ? _c("confirm-order-box", {
            attrs: { id: "confirm-box" },
            on: { confirm: _vm.handleConfirm, close: _vm.handleConfirm }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }