var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "area_coin_list" },
    [
      _c("ol", { staticClass: "list_header" }, [
        _c("li", [_vm._v(_vm._s(_vm.$t("tradeLang.lang3")))]),
        _c("li", [_vm._v(_vm._s(_vm.$t("tradeLang.lang4")))]),
        _c("li", { staticClass: "li_center" }, [
          _vm._v(_vm._s(_vm.$t("tradeLang.lang5a")))
        ])
      ]),
      _vm._l(_vm.coinList, function(item, index) {
        return _c(
          "ul",
          {
            key: index,
            staticClass: "list_box",
            on: {
              click: function($event) {
                return _vm.goDetails(item)
              }
            }
          },
          [
            _c("li", [
              _c("p", [
                _c("img", { attrs: { src: _vm.getImgUrl(item.logo) } }),
                _vm._v(
                  " " + _vm._s(item.tradcoin) + "/" + _vm._s(item.maincoin)
                )
              ])
            ]),
            _c("li", [_c("span", [_vm._v(_vm._s(item.price))])]),
            _c("li", [
              _c(
                "section",
                {
                  class: [
                    item.rise >= 0 ? "rise_green" : "rise_red",
                    "text_hide"
                  ]
                },
                [
                  item.rise > 0 ? _c("em", [_vm._v("+")]) : _vm._e(),
                  _vm._v(_vm._s(item.rise) + "%")
                ]
              )
            ])
          ]
        )
      }),
      _c("div", { staticClass: "no_data" }, [
        _c("p", { staticClass: "icon_no_data" }),
        _c("span", [_vm._v(_vm._s(_vm.$t("commonLang.lang4")))])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }