import { render, staticRenderFns } from "./cycleMetal.vue?vue&type=template&id=f67ddc8c&scoped=true&"
import script from "./cycleMetal.vue?vue&type=script&lang=js&"
export * from "./cycleMetal.vue?vue&type=script&lang=js&"
import style0 from "./cycleMetal.vue?vue&type=style&index=0&id=f67ddc8c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f67ddc8c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/mumulei/ideaProject/datagopro/bc.exchange/01_Code/H5/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f67ddc8c')) {
      api.createRecord('f67ddc8c', component.options)
    } else {
      api.reload('f67ddc8c', component.options)
    }
    module.hot.accept("./cycleMetal.vue?vue&type=template&id=f67ddc8c&scoped=true&", function () {
      api.rerender('f67ddc8c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/futures/cycleMetal.vue"
export default component.exports