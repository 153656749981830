import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.replace";
import _objectSpread from "/Users/mumulei/ideaProject/datagopro/bc.exchange/01_Code/H5/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
export default {
  computed: _objectSpread({}, mapState({
    rateData: function rateData(state) {
      return state.wallet.rateData;
    }
  })),
  props: ['type', 'coinList'],
  methods: {
    goDetails: function goDetails(data) {
      if (this.type === 'symbols') {
        sessionStorage.setItem('selectedCoin', JSON.stringify(data));
        sessionStorage.setItem('tradeActive', 1);
        sessionStorage.setItem('tradeType', 0);
        this.$router.replace('/trade');
      } else if (this.type === 'cycleForex') {
        sessionStorage.setItem('cycleForexCoinParams', JSON.stringify(data));
        sessionStorage.setItem('tradeActive', 3);
        this.$router.replace('/trade');
      } else if (this.type === 'cycleMetal') {
        sessionStorage.setItem('cycleMetalCoinParams', JSON.stringify(data));
        sessionStorage.setItem('tradeActive', 4);
        this.$router.replace('/trade');
      } // sessionStorage.setItem('marketDetails', JSON.stringify(data))
      // const type = this.type == 'contract' ? 'contract' : 'trad'
      // this.pushPath('/marketDetails/' + type)

    },
    getImgUrl: function getImgUrl(url) {
      return "/api/res/preview/".concat(url);
    }
  }
};