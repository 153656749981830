import _objectSpread from "/Users/mumulei/ideaProject/datagopro/bc.exchange/01_Code/H5/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
export default {
  data: function data() {
    return {
      searchVal: '',
      hideChecked: false
    };
  },
  computed: _objectSpread({}, mapState({
    futuresAmount: function futuresAmount(state) {
      return state.wallet.futuresAmount;
    },
    futuresCoinList: function futuresCoinList(state) {
      return state.wallet.futuresCoinList;
    },
    rateData: function rateData(state) {
      return state.wallet.rateData;
    }
  })),
  created: function created() {
    this.$store.dispatch('wallet/getFuturesAssets');
  }
};