import "core-js/modules/es.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import coinExchange from '@/views/coinExchange/mainIndex.vue';
import cycle from '@/views/futures/cycle.vue';
import cycleForex from '@/views/futures/cycleForex.vue';
import cycleMetal from '@/views/futures/cycleMetal.vue';
export default {
  components: {
    coinExchange: coinExchange,
    cycle: cycle,
    cycleForex: cycleForex,
    cycleMetal: cycleMetal
  },
  data: function data() {
    return {
      active: Number(sessionStorage.getItem('tradeActive')) || 1
    };
  },
  watch: {
    active: function active(val) {
      sessionStorage.setItem('tradeActive', val);
    }
  },
  created: function created() {
    this.$store.dispatch('common/showFooter', true);
    this.$store.dispatch('common/updateSelectedFooter', 2);
  },
  mounted: function mounted() {
    var tradeTop = this.$refs.tradeTop;
    tradeTop.style.paddingTop = "".concat(this.$barHeight, "px");
    this.$refs.tradeBox.style.marginTop = "".concat(tradeTop.clientHeight, "px");
  },
  methods: {
    goBack: function goBack() {
      // 方案1: 如果有上一页历史记录,则返回上一页
      // if (window.history.length > 1) {
      //   this.$router.back()
      // } else {
      //   // 如果没有历史记录,则导航到市场页
      //   this.pushPath('/market')
      // }
      // 方案2: 直接返回市场页
      this.pushPath('/market');
    },
    // 添加新的滚动方法
    scrollToCenter: function scrollToCenter(event) {
      var nav = event.target.parentElement; // 获取 ol 元素

      var targetLi = event.target; // 获取被点击的 li 元素
      // 计算需要滚动的位置

      var navWidth = nav.offsetWidth;
      var targetLeft = targetLi.offsetLeft;
      var targetWidth = targetLi.offsetWidth; // 计算目标元素的中心位置

      var scrollPosition = targetLeft - navWidth / 2 + targetWidth / 2; // 使用平滑滚动

      nav.scrollTo({
        left: scrollPosition,
        behavior: 'smooth'
      });
    }
  }
};