import "core-js/modules/es.array.concat";
import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import _objectSpread from "/Users/mumulei/ideaProject/datagopro/bc.exchange/01_Code/H5/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import tabHeader from '@/components/tabHeader.vue';
import { entrustOrder, entrustHistory, closeEntrust } from '@api/exchange';
export default {
  components: {
    tabHeader: tabHeader
  },
  name: 'CoinRecord',
  data: function data() {
    return {
      timer: null,
      active: 0,
      headerArr: [this.$t('tradeLang.lang281'), this.$t('tradeLang.lang282')],
      isload: false,
      loading: false,
      finished: true,
      searchData: {
        page: 1,
        limit: 20
      },
      orderList: [],
      selectedCoin: null
    };
  },
  props: {
    coinInfo: {
      type: Object,
      required: true
    }
  },
  watch: {
    active: function active(val) {
      if (this.coinInfo.tradcoin) {
        this.getRecord();
      }
    }
  },
  created: function created() {
    var _this = this;

    this.selectedCoin = JSON.parse(sessionStorage.getItem('selectedCoin'));
    window.addEventListener('storage', this.handleStorageChange);
    this.getRecord();
    this.startPeriodicCheck();
    this.timer = setInterval(function () {
      _this.getRecord();
    }, 60000);
  },
  mounted: function mounted() {},
  beforeDestroy: function beforeDestroy() {
    // 组件销毁前清除定时器和事件监听
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }

    window.removeEventListener('storage', this.handleStorageChange);
  },
  methods: {
    // 处理 storage 变化
    handleStorageChange: function handleStorageChange(e) {
      if (e.key === 'selectedCoin') {
        var newSelectedCoin = JSON.parse(e.newValue);
        this.selectedCoin = newSelectedCoin;

        if (newSelectedCoin && newSelectedCoin.tradcoin) {
          this.getRecord();
        }
      }
    },
    // 检查并更新数据的方法
    checkAndUpdateData: function checkAndUpdateData() {
      var storedCoin = JSON.parse(sessionStorage.getItem('selectedCoin'));

      if (storedCoin && (!this.selectedCoin || storedCoin.tradcoin !== this.selectedCoin.tradcoin || storedCoin.maincoin !== this.selectedCoin.maincoin)) {
        this.selectedCoin = storedCoin;
        this.getRecord();
      }
    },
    // 你可以定期调用检查方法
    startPeriodicCheck: function startPeriodicCheck() {
      var _this2 = this;

      setInterval(function () {
        _this2.checkAndUpdateData();
      }, 1000); // 每秒检查一次
    },
    // 获取委托记录；
    getRecord: function getRecord() {
      var _this3 = this;

      this.isload = false;
      this.orderList = [];
      this.searchData.page = 1;
      this.searchData = _objectSpread({}, this.searchData, {
        tradcoin: this.coinInfo.tradcoin,
        maincoin: this.coinInfo.maincoin
      });

      if (this.active == 0) {
        entrustOrder(this.searchData).then(function (res) {
          _this3.isload = true;
          _this3.loading = false;

          if (res.data && res.data.length > 0) {
            _this3.orderList = res.data;
            _this3.finished = res.count == _this3.orderList.length;
          } else {
            _this3.orderList = [];
          }
        });
      } else {
        entrustHistory(this.searchData).then(function (res) {
          _this3.isload = true;
          _this3.loading = false;

          if (res.data && res.data.length > 0) {
            _this3.orderList = res.data;
            _this3.finished = res.count == _this3.orderList.length;
          } else {
            _this3.orderList = [];
          }
        });
      }
    },
    loadMore: function loadMore() {
      var _this4 = this;

      this.searchData.page += 1;

      if (this.active == 0) {
        entrustOrder(this.searchData).then(function (res) {
          _this4.isload = true;
          _this4.loading = false;

          if (res.data && res.data.length > 0) {
            _this4.orderList = res.data;
            _this4.finished = res.count == _this4.orderList.length;
          } else {
            _this4.orderList = [];
          }
        });
      } else {
        entrustHistory(this.searchData).then(function (res) {
          _this4.isload = true;
          _this4.loading = false;
          _this4.orderList = _this4.orderList.concat(res.data);
          _this4.finished = res.count == _this4.orderList.length;
        });
      }
    },
    closeEntrustOrder: function closeEntrustOrder(data) {
      var _this5 = this;

      _Dialog.confirm({
        message: this.$t('tradeLang.lang133'),
        confirmButtonText: this.$t('commonLang.lang9'),
        cancelButtonText: this.$t('commonLang.lang1')
      }).then(function () {
        closeEntrust({
          id: data.autoid
        }).then(function (res) {
          _Toast(res.msg);

          _this5.getRecord();
        });
      }).catch(function () {});
    },
    refreshList: function refreshList() {
      // 刷新列表数据
      this.getRecord();
    }
  }
};