var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c("div", { ref: "homeTop", staticClass: "home_top" }, [
        _c(
          "div",
          { staticClass: "carousel" },
          [
            _c(
              "van-swipe",
              {
                ref: "bannerSwipe",
                staticClass: "banner_box",
                attrs: {
                  autoplay: 5000,
                  duration: 1000,
                  loop: true,
                  "show-indicators": true
                }
              },
              _vm._l(_vm.bannerList, function(image, index) {
                return _c("van-swipe-item", { key: index }, [
                  _c("div", { staticClass: "banner_img" }, [
                    _c("img", {
                      attrs: { src: _vm.getBannerImgUrl(image.banner) }
                    })
                  ])
                ])
              }),
              1
            ),
            _c("div", { staticClass: "home_nav" }, [
              _c("div", { staticClass: "nav_container" }, [
                _c("div", { staticClass: "nav_logo" }, [
                  _c("img", {
                    attrs: { src: require("@img/common/xlogo.png"), alt: "" }
                  })
                ]),
                _c(
                  "p",
                  {
                    staticClass: "service_img",
                    on: {
                      click: function($event) {
                        return _vm.showOnlineService()
                      }
                    }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: _vm.onlineServiceImg,
                        alt: "online service"
                      }
                    })
                  ]
                )
              ])
            ])
          ],
          1
        ),
        _c("div", { staticClass: "currentList" }, [
          _c(
            "ol",
            { staticClass: "coin_box" },
            _vm._l(_vm.overviewList, function(item) {
              return _c(
                "li",
                {
                  key: item.tradcoin,
                  on: {
                    click: function($event) {
                      return _vm.goDetails(item)
                    }
                  }
                },
                [
                  _c("p", [
                    _vm._v(_vm._s(item.tradcoin) + "/" + _vm._s(item.maincoin))
                  ]),
                  _c(
                    "i",
                    {
                      class: [
                        item.rise >= 0 ? "green_color" : "red_color",
                        "text_hide"
                      ]
                    },
                    [
                      item.rise > 0 ? _c("em", [_vm._v("+")]) : _vm._e(),
                      _vm._v(_vm._s(item.rise) + "%")
                    ]
                  ),
                  _c(
                    "h2",
                    {
                      class: [
                        item.rise >= 0 ? "green_color" : "red_color",
                        "text_hide"
                      ]
                    },
                    [_vm._v(_vm._s(item.price))]
                  )
                ]
              )
            }),
            0
          )
        ])
      ]),
      _c("div", { ref: "homeBox" }, [
        _c("div", { staticClass: "sub_banner_container" }, [
          _c(
            "div",
            {
              staticClass: "item",
              on: {
                click: function($event) {
                  return _vm.pushPath("/myTeam")
                }
              }
            },
            [
              _c("img", {
                attrs: { src: require("@img/common/banner_img_1.png"), alt: "" }
              }),
              _c("p", [_vm._v(_vm._s(_vm.$t("addLang.lang42")))])
            ]
          ),
          _c(
            "div",
            {
              staticClass: "item",
              on: {
                click: function($event) {
                  return _vm.pushPath("/market")
                }
              }
            },
            [
              _c("img", {
                attrs: { src: require("@img/common/banner_img_2.png"), alt: "" }
              }),
              _c("p", [_vm._v(_vm._s(_vm.$t("addLang.lang37")))])
            ]
          ),
          _c(
            "div",
            {
              staticClass: "item",
              on: {
                click: function($event) {
                  return _vm.pushPath("/financialManagement")
                }
              }
            },
            [
              _c("img", {
                attrs: { src: require("@img/common/banner_img_3.png"), alt: "" }
              }),
              _c("p", [_vm._v(_vm._s(_vm.$t("addLang.lang43")))])
            ]
          )
        ]),
        _c("div", { staticClass: "home_notice" }, [
          _c("label", [
            _c("img", {
              attrs: {
                src: require("@img/" +
                  _vm.$store.state.common.theme +
                  "/icon_notice.png"),
                alt: "",
                srcset: ""
              }
            })
          ]),
          _c(
            "div",
            { staticClass: "notice_box" },
            [
              _c(
                "van-swipe",
                {
                  staticStyle: { height: "0.8rem" },
                  attrs: {
                    autoplay: 5000,
                    duration: 1000,
                    "show-indicators": false,
                    vertical: ""
                  }
                },
                _vm._l(_vm.noticeList, function(item) {
                  return _c(
                    "van-swipe-item",
                    { key: item.id, staticStyle: { height: "0.8rem" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "text_hide",
                          on: {
                            click: function($event) {
                              return _vm.showNoticePopup(item)
                            }
                          }
                        },
                        [_vm._v(_vm._s(item.title))]
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c("i", {
            staticClass: "iconfont icon-arrow-right",
            on: {
              click: function($event) {
                return _vm.pushPath("/notice")
              }
            }
          })
        ]),
        _c("div", { staticClass: "quick_container" }, [
          _c(
            "div",
            {
              staticClass: "flex",
              on: {
                click: function($event) {
                  return _vm.showOnlineService()
                }
              }
            },
            [
              _c("img", {
                attrs: {
                  src: require("@img/common/quick_coin_charging.png"),
                  alt: ""
                }
              }),
              _c("p", [_vm._v(_vm._s(_vm.$t("addLang.lang44")))])
            ]
          ),
          _c(
            "div",
            {
              staticClass: "flex",
              on: {
                click: function($event) {
                  return _vm.goWithdrawal()
                }
              }
            },
            [
              _c("img", {
                attrs: {
                  src: require("@img/common/quick_withdrawal_coins.png"),
                  alt: ""
                }
              }),
              _c("p", [_vm._v(_vm._s(_vm.$t("addLang.lang45")))])
            ]
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "coin_list" },
        [
          _c("ol", { staticClass: "area_box" }, [
            _c(
              "li",
              {
                class: [
                  { font_active: _vm.areaType == "symbols" },
                  "tab_default"
                ],
                on: {
                  click: function($event) {
                    return _vm.changeType("symbols", 0)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("homeLang.lang76")))]
            ),
            _c(
              "li",
              {
                class: [
                  { font_active: _vm.areaType == "cycleForex" },
                  "tab_default"
                ],
                on: {
                  click: function($event) {
                    return _vm.changeType("cycleForex", 1)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("homeLang.lang77")))]
            ),
            _c(
              "li",
              {
                class: [
                  { font_active: _vm.areaType == "cycleMetal" },
                  "tab_default"
                ],
                on: {
                  click: function($event) {
                    return _vm.changeType("cycleMetal", 2)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("homeLang.lang78")))]
            )
          ]),
          _c(
            "van-swipe",
            {
              ref: "areaSwipe",
              staticClass: "listscroll",
              attrs: { "show-indicators": false, loop: false },
              on: { change: _vm.changeAreaType }
            },
            [
              _c(
                "van-swipe-item",
                { key: "symbols" },
                [
                  _c("areaCoinList", {
                    attrs: { type: "symbols", "coin-list": _vm.symbolsList }
                  })
                ],
                1
              ),
              _c(
                "van-swipe-item",
                { key: "cycleForex" },
                [
                  _c("areaCoinList", {
                    attrs: {
                      type: "cycleForex",
                      "coin-list": _vm.cycleForexList
                    }
                  })
                ],
                1
              ),
              _c(
                "van-swipe-item",
                { key: "cycleMetal" },
                [
                  _c("areaCoinList", {
                    attrs: {
                      type: "cycleMetal",
                      "coin-list": _vm.cycleMetalList
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "van-popup",
        {
          staticClass: "right_popup",
          attrs: { "close-on-popstate": "", "overlay-class": "block_bg" },
          model: {
            value: _vm.noticePopup,
            callback: function($$v) {
              _vm.noticePopup = $$v
            },
            expression: "noticePopup"
          }
        },
        [
          _c("nav-header", { attrs: { title: _vm.$t("homeLang.lang30") } }),
          _c("div", { staticClass: "main_content notice_details" }, [
            _c("h3", [_vm._v(_vm._s(_vm.noticeData.title))]),
            _c("p", [_vm._v(_vm._s(_vm.noticeData.wdate))]),
            _c("pre", { domProps: { innerHTML: _vm._s(_vm.noticeData.reply) } })
          ])
        ],
        1
      ),
      _vm.showConfirm
        ? _c("confirm-order-box", {
            attrs: { id: "confirm-box" },
            on: { confirm: _vm.handleConfirm, close: _vm.handleClose }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }