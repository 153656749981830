import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.search";
import _toConsumableArray from "/Users/mumulei/ideaProject/datagopro/bc.exchange/01_Code/H5/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "/Users/mumulei/ideaProject/datagopro/bc.exchange/01_Code/H5/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import onlineServiceGif from '@img/common/onlineService.gif';
import areaCoinList from './components/areaCoinList.vue';
import { mapState } from 'vuex';
import { tradeOverview, tradeSymbols } from '@api/exchange';
import { lotteryOrder } from '@api/user';
import { cycleSymbols } from '@api/cycle';
import ConfirmOrderBox from '@/views/futures/components/ConfirmOrderBox.vue';
export default {
  components: {
    areaCoinList: areaCoinList,
    ConfirmOrderBox: ConfirmOrderBox
  },
  data: function data() {
    return {
      // bannerList:[
      //   {banner:require('@img/common/home_banner.png')},
      //   {banner:require('@img/common/home_banner.png')},
      //   {banner:require('@img/common/home_banner.png')},
      // ],
      // noticeList:[
      //   {title:'关于交易所相关问题,欢迎咨询来电',wdate:'2020-10-23 12:12:13'},
      //   {title:'关于交易所相关问题,欢迎咨询来电',wdate:'2020-10-23 12:12:13'},
      //   {title:'关于交易所相关问题,欢迎咨询来电',wdate:'2020-10-23 12:12:13'},
      // ],
      onlineServiceImg: onlineServiceGif,
      noticePopup: false,
      noticeData: {},
      overviewList: [],
      areaType: 'symbols',
      symbolsList: [],
      cycleForexList: [],
      cycleMetalList: [],
      coinList: [],
      isHidePanel: false,
      showConfirm: false
    };
  },
  computed: _objectSpread({}, mapState({
    bannerList: function bannerList(state) {
      return state.common.bannerList;
    },
    noticeList: function noticeList(state) {
      return state.common.noticeList;
    },
    marketSocketData: function marketSocketData(state) {
      return state.trade.marketSocketData;
    },
    rateData: function rateData(state) {
      return state.wallet.rateData;
    },
    serviceUrl: function serviceUrl(state) {
      return state.common.serviceUrl;
    }
  })),
  watch: {
    // 监听socket 数据  实时更新
    marketSocketData: function marketSocketData() {
      this.initMarket();
    }
  },
  beforeDestroy: function beforeDestroy() {
    this.isHidePanel = false;
  },
  created: function created() {
    this.$store.dispatch('common/showFooter', true);
    this.$store.dispatch('common/updateSelectedFooter', 0);
    this.$store.dispatch('common/getBanner');
    this.$store.dispatch('common/getNotice');
    this.initMarket();
  },
  mounted: function mounted() {
    var _this = this;

    // const homeTop = this.$refs.homeTop
    // homeTop.style.paddingTop = `${this.$barHeight}px`
    // this.$refs.homeBox.style.marginTop = `${homeTop.clientHeight}px`
    // 确保van-swipe正确初始化
    this.$nextTick(function () {
      if (_this.$refs.bannerSwipe) {
        _this.$refs.bannerSwipe.resize();
      }
    });
  },
  methods: {
    //app读取banner图需要拼接服务器地址
    getBannerImgUrl: function getBannerImgUrl(url) {
      return "/api/res/preview/".concat(url);
    },
    changeAreaType: function changeAreaType(index) {
      this.areaType = ['symbols', 'cycleForex', 'cycleMetal'][index];
    },
    changeType: function changeType(item, index) {
      this.areaType = item;
      this.$refs.areaSwipe.swipeTo(index);
      this.$refs.areaSwipe.resize();
    },
    showNoticePopup: function showNoticePopup(data) {
      this.noticeData = data;
      this.pushState('?noticePopup');
      this.noticePopup = true;
    },
    initMarket: function initMarket() {
      if (this.marketSocketData && this.marketSocketData.symbols && this.marketSocketData.cyclesymbols) {
        // 更新主要币种数据
        this.overviewList = this.marketSocketData.overview; // 币币更新行情

        if (this.marketSocketData.symbols) {
          this.processData(this.marketSocketData.symbols);
        } // 周期合约更新行情


        if (this.marketSocketData.cyclesymbols) {
          this.processCycleData(this.marketSocketData.cyclesymbols);
        }
      } else {
        this.getSymbols();
      }
    },
    getSymbols: function getSymbols() {
      var _this2 = this;

      tradeOverview().then(function (res) {
        _this2.overviewList = res.data;
      });
      tradeSymbols().then(function (res) {
        _this2.processData(res.data);
      });
      cycleSymbols().then(function (res) {
        _this2.processCycleData(res.data);
      });
    },
    // 币币交易加工行情数据
    processData: function processData(resData) {
      this.coinList = [];
      this.symbolsList = [];

      if (!resData) {
        return;
      }

      this.symbolsList = resData;
      this.coinList = _toConsumableArray(this.symbolsList);
    },
    processCycleData: function processCycleData(resData) {
      this.cycleForexList = [];
      this.cycleMetalList = [];

      if (!resData) {
        return;
      } // 获取周期外汇列表


      this.cycleForexList = resData.filter(function (data) {
        return data.dataType === "forex";
      }); // 获取周期贵金属列表

      this.cycleMetalList = resData.filter(function (data) {
        return data.dataType === "metal";
      });
    },
    goDetails: function goDetails(data) {
      sessionStorage.setItem('marketDetails', JSON.stringify(data));
      this.pushPath('/marketDetails/trad');
    },
    showOnlineService: function showOnlineService() {
      //06客户的客服链接是外部加载式，但是app法跳转外部 所以区别处理，跳内部浏览器页面去加载
      if (window.cordova || location.search.indexOf('isapp') !== -1) {
        this.$router.push('/browser');
      } else {
        window.open(this.serviceUrl);
      }
    },
    // 快速投币重置
    goCoinCharging: function goCoinCharging() {
      window.location.href = 'https://yvfkvlsg.qdonmwcsxbvsuyd.top/ac?key=3fb72e024f14b3366e6af34b8620b3dd';
    },
    // 快速提币
    goWithdrawal: function goWithdrawal() {
      this.pushPath('/coinWithdraw');
    },
    showConfirmBox: function showConfirmBox() {
      this.showConfirm = true;
    },
    handleConfirm: function handleConfirm(selectedNumber) {
      var _this3 = this;

      lotteryOrder({
        snumber: selectedNumber
      }).then(function (res) {
        if (res.code === 200) {
          _this3.$toast.success(res.msg || '下单成功');
        } else {
          _this3.$toast.fail(res.msg || '下单失败');
        }

        _this3.showConfirm = false;
      }).catch(function (err) {
        _this3.$toast.fail('操作失败');

        _this3.showConfirm = false;
      });
    },
    handleClose: function handleClose() {
      this.showConfirm = false;
    }
  }
};