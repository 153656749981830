import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.function.name";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/web.dom-collections.for-each";
import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import _toConsumableArray from "/Users/mumulei/ideaProject/datagopro/bc.exchange/01_Code/H5/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "/Users/mumulei/ideaProject/datagopro/bc.exchange/01_Code/H5/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import kline from '@/views/home/components/kline';
import dragBox from './components/dragBox';
import { mapState } from 'vuex';
import { systemTime } from '@api/common';
import { contractBalance } from '@api/wallet';
import { lotteryOrder } from '@api/user';
import { cycleSymbols, cycleInfo, cycleBuyMarket, cycleSellMarket, positionByCoin, historyPositionByCoin } from '@api/cycle';
import ConfirmOrderBox from '@/views/futures/components/ConfirmOrderBox.vue';
export default {
  components: {
    kline: kline,
    dragBox: dragBox,
    ConfirmOrderBox: ConfirmOrderBox
  },
  data: function data() {
    return {
      tradeType: sessionStorage.getItem('cycleType') || 1,
      nowDate: null,
      timeInterval: null,
      leftPopup: false,
      coinList: [],
      coinInfo: {},
      klineKey: Date.now().toString() + Math.floor(Math.random() * 1000).toString(),
      tradePopup: false,
      payCoinPopup: false,
      payCoinList: [],
      payCoinData: {},
      leverPopup: false,
      leverList: [],
      leverData: {},
      coinSheet: false,
      coinActions: [],
      usable: '',
      gasType: 'profit',
      profitGas: '0.00',
      lossGas: '0.00',
      tradeNum: '',
      active: 0,
      topOrder: [],
      positionOrder: [],
      historyOrder: [],
      searchData: {
        page: 1,
        limit: 15
      },
      isload: false,
      loading: false,
      finished: true,
      showConfirm: false,
      orderSeq: ''
    };
  },
  computed: _objectSpread({}, mapState({
    marketSocketData: function marketSocketData(state) {
      return state.trade.marketSocketData;
    },
    cycleSocketData: function cycleSocketData(state) {
      return state.trade.cycleSocketData;
    },
    cycleTopData: function cycleTopData(state) {
      return state.trade.cycleTopData;
    },
    headerHeight: function headerHeight(state) {
      return state.common.headerHeight;
    },
    offsetTime: function offsetTime(state) {
      return state.common.offsetTime;
    },
    rateData: function rateData(state) {
      return state.wallet.rateData;
    }
  })),
  watch: {
    // 监听socket 数据  实时更新
    marketSocketData: function marketSocketData(data) {
      // 更新行情
      data.cyclesymbols && (this.coinList = data.cyclesymbols.filter(function (data) {
        return data.dataType === "forex";
      }));
    },
    // 监听socket 数据  实时更新
    cycleSocketData: function cycleSocketData(data) {
      // 更新行情
      if (data.cycleinfo && this.coinInfo) {
        if (data.cycleinfo.autoid == this.coinInfo.autoid) {
          this.setCoinData(data.cycleinfo);
        }
      }
    },
    cycleTopData: function cycleTopData(data) {
      console.log(11111111111, data);
      this.setTopData(data);
    }
  },
  created: function created() {
    if (this.cycleSocketData && this.cycleSocketData.cycleinfo) {
      if (this.marketSocketData && this.marketSocketData.cyclesymbols) {
        this.initCoinList(this.marketSocketData.cyclesymbols, 1);
      } else {
        this.getCoinList();
      }
    } else {
      this.getCoinList();
    }
  },
  mounted: function mounted() {},
  methods: {
    // 获取交易币种列表
    getCoinList: function getCoinList() {
      var _this = this;

      cycleSymbols().then(function (res) {
        _this.initCoinList(res.data);
      });
    },
    initCoinList: function initCoinList(resData, type) {
      var cycleForexCoinParams, coinData; // 过滤非外汇币种

      this.coinList = resData.filter(function (data) {
        return data.dataType === "forex";
      });
      console.log("this.coinforexList:".concat(JSON.stringify(this.coinList)));
      cycleForexCoinParams = JSON.parse(sessionStorage.getItem('cycleForexCoinParams')) || {};
      console.log("cycleForexCoinParams:".concat(JSON.stringify(cycleForexCoinParams))); // 检查上次是否已经操作过选择币种 浏览器记住了上次选择的币种 本次默认上次选择币种;

      if (Object.keys(cycleForexCoinParams).length) {
        coinData = cycleForexCoinParams;
      } else {
        coinData = this.coinList[0];
        sessionStorage.setItem('cycleForexCoinParams', JSON.stringify(coinData));
      }

      if (type == 1) {
        if (this.cycleSocketData.cycleinfo.tradcoin == coinData.tradcoin) {
          // 如果ws有数据 取ws的数据 不调接口
          this.coinInfo = this.cycleSocketData.cycleinfo;
        } else {
          this.getCoinInfo(coinData);
        }
      } else {
        this.getCoinInfo(coinData);
      } // 更新账户信息


      this.getOffsetTime(coinData);
      this.getHistoryPosition(coinData);
      this.sendWsParams(coinData);
      console.log("coinInfo: ".concat(JSON.stringify(this.coinList)));
    },
    // 获取交易币种信息
    getCoinInfo: function getCoinInfo(data) {
      var _this2 = this;

      cycleInfo({
        maincoin: data.maincoin,
        tradcoin: data.tradcoin
      }).then(function (res) {
        _this2.setCoinData(res.data);
      });
    },
    setCoinData: function setCoinData(resData) {
      var optionalData = JSON.parse(localStorage.getItem('optionalCycleData')) || [];
      resData.stringName = "".concat(resData.tradcoin, "/").concat(resData.maincoin);
      optionalData.forEach(function (id) {
        resData.isOptional = resData.autoid == id;
      });
      this.coinInfo = resData;
    },
    changePerioad: function changePerioad(data) {
      this.sendWsParams(this.coinInfo, data.Min);
    },
    sendWsParams: function sendWsParams(coinData, klineMinute) {
      var _this3 = this;

      // console.log(this.$ws);
      if (this.$ws && this.$ws.readyState == 1) {
        // 通知 ws 更新币种数据
        var key = "".concat(coinData.maincoin, "_").concat(coinData.tradcoin, "_cycle_").concat(klineMinute || 1);
        this.$ws.send(key);
        sessionStorage.setItem('webSocketKey', key);
      } else {
        // 如ws没准备好  1.5s后再试
        setTimeout(function () {
          // 通知 ws 更新币种数据
          _this3.sendWsParams(coinData);
        }, 1500);
      }
    },
    // 操作自选收藏
    handleOptional: function handleOptional() {
      var _this4 = this;

      this.$set(this.coinInfo, 'isOptional', !this.coinInfo.isOptional); // 重置缓存中的coinInfo数据，更新自选

      sessionStorage.setItem('cycleForexCoinParams', JSON.stringify(this.coinInfo)); // 更新coinList 中的自选状态

      this.coinList.forEach(function (data) {
        if (data.autoid == _this4.coinInfo.autoid) {
          data.isOptional = !data.isOptional;
        }
      }); // 获取缓存中的自选数据

      var optionalData = JSON.parse(localStorage.getItem('optionalCycleData')) || []; // 如果币种当前状态为 已自选状态 直接添加进缓存中数据

      if (this.coinInfo.isOptional) {
        optionalData.push(this.coinInfo.autoid);
      } else {
        // 过滤掉取消自选的币种数据
        optionalData = optionalData.filter(function (id) {
          return !(id == _this4.coinInfo.autoid);
        });
      }

      localStorage.setItem('optionalCycleData', JSON.stringify(optionalData));
    },
    showLeftPopup: function showLeftPopup() {
      this.leftPopup = true;
      var leftPopup = this.$refs.leftPopup.$el;
      leftPopup.style.paddingTop = "".concat(this.$barHeight, "px");
    },
    chooseCoin: function chooseCoin(coinData) {
      this.leftPopup = false; // 选择的当前币种  不处理

      if (coinData.tradcoin != this.coinInfo.tradcoin) {
        // 更新币种信息；
        this.coinInfo = coinData;
        sessionStorage.setItem('cycleForexCoinParams', JSON.stringify(coinData));
        this.klineKey = Date.now().toString() + Math.floor(Math.random() * 1000).toString();
        this.topOrder = []; // 重新获取选中币种信息和账户信息

        this.getCoinInfo(coinData);
        this.sendWsParams(coinData);
        this.getPosition(coinData);
        this.getHistoryPosition(coinData);
      }
    },
    setTopData: function setTopData(data) {
      var orderList = this.topOrder;
      data.forEach(function (top) {
        orderList.forEach(function (order, index) {
          if (top.autoid == order.autoid) {
            orderList.splice(index, 1);
          }
        });
      });
      this.topOrder = [].concat(_toConsumableArray(data), _toConsumableArray(orderList));

      if (this.topOrder.length > 20) {
        this.topOrder.length = 20;
      }
    },
    getOffsetTime: function getOffsetTime(coinData) {
      var _this5 = this;

      systemTime().then(function (res) {
        var offsetTime = res.data - Date.now();

        _this5.$store.dispatch('common/setOffsetTime', offsetTime);

        coinData && _this5.getPosition(coinData);
      });
    },
    getImgUrl: function getImgUrl(url) {
      return "/api/res/preview/".concat(url);
    },
    showTradePopup: function showTradePopup(type) {
      this.coinInfo.limit.forEach(function (data) {
        data.name = data.paycoin;
      });
      console.log("this.coinInfo: ".concat(JSON.stringify(this.coinInfo)));
      this.payCoinList = this.coinInfo.limit;
      this.payCoinData = this.payCoinList[0];
      this.leverList = this.coinInfo.lever;
      this.leverData = this.leverList[0];
      console.log("this.leverData: ".concat(JSON.stringify(this.leverData)));
      this.initInterval();
      this.tradeType = type;
      this.tradeNum = '';
      this.tradePopup = true;
      this.getBalance();
      console.log("===============================");
    },
    selectPayCoin: function selectPayCoin(data) {
      this.payCoinData = data;
      this.usable = '0.00';
      this.getBalance();
    },
    chooseCycle: function chooseCycle(data) {
      this.leverData = data;

      if (this.tradeNum && Number(data.minnumber) > Number(this.tradeNum)) {
        this.tradeNum = '';
      }
    },
    // 同步服务器时间
    initInterval: function initInterval() {
      var _this6 = this;

      var count = 0; // 计数器

      var startTime = Date.now();
      var intervalTime = 1000;

      if (this.offsetTime) {
        this.timeInterval && clearInterval(this.timeInterval);
        this.nowDate = Date.now() + this.offsetTime;
        this.timeInterval = setInterval(function () {
          _this6.nowDate += 1000;
          var runTime = Date.now();
          var offsetTime = runTime - (startTime + count * 1000) - 1000;
          intervalTime = 1000 - offsetTime; // console.log(intervalTime);

          count++;
        }, intervalTime);
      }
    },
    // 获取余额
    getBalance: function getBalance() {
      var _this7 = this;

      contractBalance({
        coin: this.payCoinData.paycoin
      }).then(function (res) {
        _this7.usable = Math.max(res.data.usable, 0);
      });
    },
    addOrder: function addOrder(type) {
      var _this8 = this;

      if (!this.tradeNum) {
        _Toast(this.$t('tradeLang.lang259'));

        return;
      } else if (isNaN(this.tradeNum)) {
        _Toast(this.$t('tradeLang.lang260'));

        return;
      } else if (this.tradeNum > Number(this.usable)) {
        _Toast(this.$t('tradeLang.lang261'));

        return;
      } else if (this.tradeNum < Number(this.leverData.minnumber)) {
        _Toast(this.$t('tradeLang.lang262', {
          min: this.leverData.minnumber
        }));

        return;
      } // 下单时判断socket连接是否正常 如果不正常 不允许下单
      // if (this.$ws && this.$ws.readyState == 1) {
      // 判断socket 中的时间和服务器的时间差  如果大于5s 不允许下单
      // if (Math.abs(this.coinInfo.new_date - this.nowDate) > 5000) {
      //   this.tradeNum = ''
      //   Toast(this.$t('tradeLang.lang264'))
      //   setTimeout(() => {
      //     window.location.reload()
      //   }, 3000)
      //   return
      // }


      var params = {
        maincoin: this.coinInfo.maincoin,
        tradcoin: this.coinInfo.tradcoin,
        number: this.tradeNum,
        paycoin: this.payCoinData.paycoin,
        lever: this.leverData.lever
      };

      if (type == 'buy') {
        cycleBuyMarket(params).then(function (res) {
          _Toast(res.msg);

          console.log("cycleBuyMarket: ".concat(JSON.stringify(res)));
          _this8.tradeNum = '';
          _this8.tradePopup = false; // 关闭定时器

          _this8.timeInterval && clearInterval(_this8.timeInterval);

          _this8.getPosition(_this8.coinInfo);

          if (res.data !== '') {
            _this8.orderSeq = res.data;
            _this8.showConfirm = true;
          }
        });
      } else {
        cycleSellMarket(params).then(function (res) {
          _Toast(res.msg);

          console.log("cycleSellMarket: ".concat(JSON.stringify(res)));
          _this8.tradeNum = '';
          _this8.tradePopup = false; // 关闭定时器

          _this8.timeInterval && clearInterval(_this8.timeInterval);

          _this8.getPosition(_this8.coinInfo);

          if (res.data !== '') {
            _this8.orderSeq = res.data;
            _this8.showConfirm = true;
          }
        });
      } // } else {
      //   this.tradeNum = ''
      //   Toast(this.$t('tradeLang.lang265'))
      //   setTimeout(() => {
      //     window.location.reload()
      //   }, 3000)
      // }

    },
    showConfirmBox: function showConfirmBox() {
      this.showConfirm = true;
    },
    handleConfirm: function handleConfirm(selectedNumber) {
      var _this9 = this;

      lotteryOrder({
        snumber: selectedNumber,
        orderSeq: this.orderSeq
      }).then(function (res) {
        if (res.code === 200) {
          _this9.$toast.success(res.msg || '下单成功');
        } else {
          _this9.$toast.fail(res.msg || '下单失败');
        }

        _this9.showConfirm = false;
      }).catch(function (err) {
        _this9.$toast.fail('操作失败');

        _this9.showConfirm = false;
      });
    },
    handleClose: function handleClose() {
      this.showConfirm = false;
    },
    getPosition: function getPosition(data) {
      var _this10 = this;

      positionByCoin({
        maincoin: data.maincoin,
        tradcoin: data.tradcoin
      }).then(function (res) {
        _this10.positionOrder = res.data;
      });
    },
    updatePosition: function updatePosition(data) {
      // 倒计时结束 更新持仓和历史持仓
      this.active = 1;
      this.getPosition(data);
    },
    refreshHistory: function refreshHistory() {
      var _this11 = this;

      // 定时器，如果下单后每隔1s查数据 直到5秒后不再重复查
      var intervalObj = null;
      var timeNum = 4;
      this.getHistoryPosition(this.coinInfo);
      intervalObj = setInterval(function () {
        timeNum--;

        if (timeNum >= 0) {
          _this11.getHistoryPosition(_this11.coinInfo);
        } else {
          intervalObj && clearInterval(intervalObj);
        }
      }, 1500);
    },
    getHistoryPosition: function getHistoryPosition(coinData) {
      this.isload = false;
      this.searchData.page = 0;
      this.searchData.tradcoin = coinData.tradcoin;
      this.searchData.maincoin = coinData.maincoin;
      this.loadMore();
    },
    loadMore: function loadMore() {
      var _this12 = this;

      this.searchData.page += 1;
      historyPositionByCoin(this.searchData).then(function (res) {
        _this12.isload = true;
        _this12.loading = false;

        if (res.data && res.data.length) {
          _this12.historyOrder = _this12.searchData.page == 1 ? res.data : [].concat(_toConsumableArray(_this12.historyOrder), _toConsumableArray(res.data));
        } else {
          _this12.historyOrder = [];
        }

        _this12.finished = res.count == _this12.historyOrder.length;
      });
    },
    showDetailsPopup: function showDetailsPopup() {}
  }
};