export default {
  lang1: '個人中心',
  lang2: '我的餘額(USDT)',
  lang3: '凍結餘額(USDT)',
  lang4: '初級認證',
  lang5: '綁定銀行卡',
  lang6: '設定資金密碼',
  lang7: '設定登入密碼',
  lang8: '幫助中心',
  lang9: '團隊',
  lang10: '邀請',
  lang11: '語言',
  lang12: '設定',
  lang13: '登入',
  lang14: '首頁',
  lang15: '初始投資額',
  lang16: '限制投資金額',
  lang17: '鎖定天數',
  lang18: '專案進度:',
  lang19: '詳情',
  lang20: '基金',
  lang21: '平均收益',
  lang22: '專案週期',
  lang23: '初始投入金額',
  lang24: '基金介紹',
  lang25: '我的投資',
  lang26: '總投入值:',
  lang27: '累計收益:',
  lang28: '持倉數:',
  lang29: '交易記錄',
  lang30: '基金名稱:',
  lang31: '鎖定天數:',
  lang32: '日收益:',
  lang33: '投入金額:',
  lang34: '預計收益:',
  lang35: '收據：',
  lang36: '狀態：',
  lang37: '秒合約',
  lang38: '幣幣交易',
  lang39: '我的餘額(USDT)',
  lang40: '凍結餘額(USDT)',
  lang41: '我的',
  lang42: '推廣中心',
  lang43: '財務管理',
  lang44: '快速充幣',
  lang45: '快速提幣',
  lang45a: '在線客服',
  lang46: '邀請好友一起賺金幣',
  lang47: '邀請好友並儲值立即獲得佣金',
  lang48: '邀請碼：',
  lang49: '推廣連結：',
  lang50: '理財記錄',
  lang51: '訂單',
  lang52: '搜尋理財',
  lang53: '充提記錄',
  lang54: '即時參與',
  lang55: '持卡人',
  lang56: '抽獎訂單',
  lang57: '沒有更多了',
  lang58: '已中獎',
  lang59: '未中獎',
  lang60: '選擇號碼',
  lang61: '中獎號碼',
  lang62: '獎金',
  lang63: '下單時間',
  lang64: '暫無數據',
  lang65: '抽獎記錄',
  lang66: '確認訂單',
  lang67: '提示：如果倒計時結束且未做選擇，訂單將自動提交',
  lang68: '未開獎',
  lang69: '恭喜！',
  lang70: '恭喜您的號碼獲得獎金',
  lang71: '號碼',
  lang72: '獎金金額',
  lang73: '確認',
  lang74: '還沒有添加銀行卡號',
  lang75: '去添加',
  lang76: '還沒有添加提幣地址',
  lang77: '添加銀行卡',
  lang78: '銀行卡號',
  lang79: '請輸入收款銀行卡號',
  lang80: '銀行名稱',
  lang81: '請輸入銀行名稱',
  lang82: '請輸入姓名',
  lang83: '提交',
  lang84: '添加錢包地址',
  lang85: '鏈名稱',
  lang86: '提幣地址',
  lang87: '請輸入錢包地址',
  lang88: '協議',
  lang89: '取消',
  lang90: '幣幣資產',
  lang91: '合約資產'
};